import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    formsList: [],
    formType: [],
    formOrganizations: [],
  },
  reducers: {
    setFormsList: (state, { payload }) => {
      state.formsList = payload;
    },
    setFormDetails: (state, { payload }) => {
      state.formDetails = payload;
    },
    setFormOrganizations: (state, { payload }) => {
      state.formOrganizations = payload;
    },
    setFormVersions: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.formVersions?.content, ...payload?.data?.content] };
        state.formVersions = mergeData;
      } else {
        state.formVersions = payload.data;
      }
    },
    setFormVersionDetails: (state, { payload }) => {
      state.formVersionDetails = payload;
    },
    setFormType: (state, { payload }) => {
      state.formType = payload;
    },
  },
});

export const getFormType = payload => async dispatch => {
  const { data } = await api.get(`/form_types`, { params: payload.param });
  dispatch(setFormType(data));
};

export const createForm = payload => async dispatch => {
  try {
    const { request, pageNumber } = payload;
    const { data } = await api.post(`/forms`, { ...request });
    dispatch(getFormList({ params: { page: pageNumber }, url: '' }))
      .then(response => {
        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFormDetails = payload => async dispatch => {
  try {
    const { request, id } = payload;
    const { data } = await api.put(`/forms/${id}`, { ...request });
    dispatch(getFormList({ params: { page: id }, url: '' }))
      .then(response => {
        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormsList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/self-service/forms`, { params: payload.params });
    dispatch(setFormsList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/self-service/forms/${payload.id}`);
    dispatch(setFormDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormVersions = payload => async dispatch => {
  try {
    const { id, params, merge } = payload;
    const { data } = await api.get(`/forms/${id}/versions`, {
      params: params,
    });
    dispatch(setFormVersions({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormOrganizations = payload => async dispatch => {
  try {
    const { id } = payload;
    const { data } = await api.get(`/forms/${id}/organizations`);
    dispatch(setFormOrganizations(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const addFormOrganization = payload => async dispatch => {
  try {
    const { id, body } = payload;
    const { data } = await api.post(`/forms/${id}/organizations`, body);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteFormOrganization = payload => async dispatch => {
  try {
    const { id, orgId } = payload;
    const { data } = await api.delete(`/forms/${id}/organizations/${orgId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFormVersionToRetire = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.put(`/forms/${formId}/versions/${versionId}/archive`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const duplicateFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.post(`/forms/${formId}/versions/${versionId}/duplicate`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.get(`/forms/${formId}/versions/${versionId}/export`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const importFormVersion = payload => async () => {
  try {
    const { formId, versionId, body } = payload;
    const { data } = await api.post(`/forms/${formId}/versions/import`, body);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const generateFormVersion = payload => async () => {
  try {
    const { versionId, formId, body } = payload;
    const { data } = await api.post(`/forms/${formId}/versions/${versionId}/pdf`, body, { responseType: 'blob' });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const publishFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.put(`/forms/${formId}/versions/${versionId}/publish`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormVersionDetails = payload => async dispatch => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.get(`/forms/${formId}/versions/${versionId}`);
    dispatch(setFormVersionDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const rePublishFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.put(`/forms/${formId}/versions/${versionId}/revert`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormList = payload => async dispatch => {
  const { search, page } = payload.params;
  const url = `/forms?search=${search ? search : ''}&page=${page ? page : 0}&size=13&sort=name${
    payload?.url && `${payload?.url}`
  }`;
  const { data } = await api.get(url);
  dispatch(setFormsList(data));
  Promise.resolve(data);
};

export const {
  setFormsList,
  setFormDetails,
  setFormVersions,
  setFormVersionDetails,
  setFormType,
  setFormOrganizations,
} = formsSlice.actions;
export default formsSlice.reducer;
