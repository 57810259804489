import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import Dropdown from '../../../components/FormElements/dropdown';
import InputElement from '../../../components/FormElements/input';
import { formComponentTypes } from '../../../constant/optionData';
import { AdvancedConfig } from './advanced-config';
import { AlignmentConfig } from './alignment-config';
import { ChooserOptionsConfig } from './chooser-option-config';
import { GeneralConfig } from './general-config';
import { HeaderGeneralConfig } from './header-general-config';
import { ImageGeneralConfig } from './image-general-config';
import { MultichoiceGeneralConfig } from './multichoice-general-config';
import { PaymentGeneralConfig } from './payment-general-config';
import { ProductGeneralConfig } from './product-general-config';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import { ColorPicker } from '../common/color-picker';
import DropDown from '../../../components/McDropdown';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { fontStyleOptions } from '../../../constant/optionData';

import styled from 'styled-components';

export const ComponentConfigs = ({
  component,
  setSelectedComponent,
  deleteComponent,
  updateWidget,
  panelId,
  selectedWidget,
  selectComponent,
  panelWidgets,
}) => {
  const { component_type, row, column, width, height, x, y, font } = component;
  const { t } = useTranslation();
  const { fonts } = useContext(FormBuilderContext);
  const { name, style, size, color } = font;
  const [colorPicker, setColorPicker] = useState('');
  const updateComponentDetails = comp => {
    updateWidget({
      ...selectedWidget,
      panelId: panelId,
      components: selectedWidget.components.map(c => (c.id === comp.id ? comp : c)),
    });
    selectComponent(comp);
  };
  return (
    <ConfigWrapper className="flex-column flex-1 overflow-hidden">
      <div className="flex items-center">
        <div className="flex items-center pxy-1 mr-2 cursor" onClick={() => setSelectedComponent(null, false)}>
          <ArrowLeftIcon height={16} width={16} />
        </div>
        <label className="flex-1 medium-text font-16">{t('COMPONENT_DETAILS')}</label>
        {/* <div className="pxy-1 radius-2 flex items-center justify-center cursor delete-widget">
          <DeleteIcon
            height={16}
            width={16}
            onClick={e => {
              e.stopPropagation();
              deleteComponent(component);
            }}
          />
        </div> */}
      </div>
      <div className="flex-column overflow-scroll px-1" style={{ height: 'calc(100vh - 100px)' }}>
        <div className="flex-column my-8">
          <label className=" flex-1 medium-text font-16">{t('OPTIONS_UC')}</label>
          <Dropdown
            className="mt-6"
            name="Component type"
            placeholder="Select type"
            options={formComponentTypes}
            font={{ size: 12 }}
            selectedValue={formComponentTypes.find(c => c.value === component_type)}
            onChange={option => updateComponentDetails({ ...component, component_type: option.value })}
          />
          <div className="mt-6 flex col-gap-4">
            <InputElement
              name="Row"
              onChange={value => updateComponentDetails({ ...component, row: value })}
              value={row}
              label="Row"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
            <InputElement
              name="Column"
              onChange={value => updateComponentDetails({ ...component, column: value })}
              value={column}
              label="Column"
              className="w-full"
              font={{ size: 12 }}
            />
          </div>
          <div className="mt-6 flex items-center col-gap-4">
            <InputElement
              name="Width"
              onChange={value => updateComponentDetails({ ...component, width: value })}
              value={width}
              label="Width"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
            <InputElement
              name="Height"
              onChange={value => updateComponentDetails({ ...component, height: value })}
              value={height}
              label="Height"
              className="w-full"
              font={{ size: 12 }}
            />
          </div>
          <div className="mt-6 flex col-gap-4">
            <InputElement
              name="X"
              onChange={value => updateComponentDetails({ ...component, x: value })}
              value={x}
              label="X"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
            <InputElement
              name="Y"
              onChange={value => updateComponentDetails({ ...component, y: value })}
              value={y}
              label="Y"
              className="w-full"
              font={{ size: 12 }}
            />
          </div>
        </div>
        {component_type && (
          <Fragment>
            <div className="hr-line" />
            {component_type === 'HEADER' ? (
              <HeaderGeneralConfig component={component} setSelectedComponent={updateComponentDetails} />
            ) : component_type === 'PRODUCT' ? (
              <ProductGeneralConfig component={component} setSelectedComponent={updateComponentDetails} />
            ) : component_type === 'PAYMENT' ? (
              <PaymentGeneralConfig component={component} setSelectedComponent={updateComponentDetails} />
            ) : component_type === 'IMAGE' ? (
              <ImageGeneralConfig component={component} setSelectedComponent={updateComponentDetails} />
            ) : component_type === 'MULTICHOICE' ? (
              <MultichoiceGeneralConfig component={component} setSelectedComponent={updateComponentDetails} />
            ) : (
              <div className="flex-column">
                <div className="pb-8 border-bottom mt-8 flex-column">
                  <label className="medium-text">{t('FONT_UC')}</label>
                  <div className="flex items-center mt-6">
                    <label className="flex regular-text flex-1">{t('DEFAULT_FONT')}</label>
                    <DropDown
                      className="flex-1"
                      options={fonts}
                      selected={fonts.find(f => f.name === name)}
                      placeholder="Font"
                      setSelected={option =>
                        updateComponentDetails({ ...component, font: { ...component.font, name: option.name } })
                      }
                    />
                  </div>
                  <div className="flex items-center mt-4">
                    <label className="flex regular-text flex-1">{t('FONT_STYLE')}</label>
                    <DropDown
                      className="flex-1"
                      options={fontStyleOptions}
                      selected={fontStyleOptions.find(fs => fs.value === style)}
                      placeholder="Style"
                      setSelected={option =>
                        updateComponentDetails({ ...component, font: { ...component.font, style: option.value } })
                      }
                    />
                  </div>
                  <div className="flex items-center mt-4">
                    <label className="flex regular-text flex-1">Size</label>
                    <input
                      className="input general-settings-input w-full"
                      placeholder="Size"
                      type="number"
                      value={size}
                      onChange={({ target: { value } }) =>
                        updateComponentDetails({ ...component, font: { ...component.font, size: value } })
                      }
                    />
                  </div>
                  <ColorPicker
                    className="flex items-centers mt-4"
                    label={'Default font color'}
                    settingKey={'default_font_color'}
                    selectedColor={color}
                    onChangeColor={(settingKey, color) =>
                      updateComponentDetails({ ...component, font: { ...component.font, color: color } })
                    }
                    colorPicker={colorPicker}
                    setColorPicker={setColorPicker}
                  />
                  {component_type !== 'IMAGE' && (
                    <AlignmentConfig component={component} setSelectedComponent={updateComponentDetails} />
                  )}
                </div>
                <GeneralConfig
                  component={component}
                  setSelectedComponent={updateComponentDetails}
                  panelWidgets={panelWidgets}
                />
              </div>
            )}
            {component_type === 'CHOOSER' && (
              <Fragment>
                <div className="hr-line" />
                <ChooserOptionsConfig component={component} setSelectedComponent={updateComponentDetails} />
              </Fragment>
            )}
            <div className="hr-line" />
            <AdvancedConfig component={component} setSelectedComponent={updateComponentDetails} />
          </Fragment>
        )}
      </div>
    </ConfigWrapper>
  );
};

const ConfigWrapper = styled('div')`
  .input-element {
    height: 40px !important;
    border-radius: 16px;
  }
`;
